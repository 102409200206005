import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const PoetForeignerPrisoner = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Poet, the Foreigner, or the Prisoner?" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
        <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>The Poet, the Foreigner, or the Prisoner?</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Jan. 19, 2021) </p>
        </div>
        <div className="articleBody">
        <p>
        Let's play a game.
        </p>
        <p>
        Each of the following quotes is either from James Baldwin's <br /> <i>The Fire Next Time </i>(1963), Frantz Fanon's <i>The Wretched of the Earth </i>(1961), or George Jackson's <i>Soledad Brother: The Prisoner Letters of George Jackson </i>(1970). 
        </p>
        <p>
        The first person to message me with the correct attributions for each (and every) quote wins a humble prize (say, majority ownership of a certain social media app that's popular w/ the teenyboppers and which a certain lame duck president personally arranged for me to purchase). 
        </p>
        <p>
        I. "[The prisoners] laugh and make merry to distract themselves from their condition, to pretend they aren’t afraid. It’s like a superstitious person whistling a happy tune as they pass by a graveyard at night."  
        </p>
        <p>
        II. "Violence alone, violence committed by the people, violence organized and educated by its leaders, makes it possible for the masses to understand social truths and gives the key to them." 
        </p>
        <p>
        III. “A civilization is not destroyed by wicked people; it is not necessary that people be wicked but only that they be spineless.”
        </p>
        <p>
        IV. "I do not mean to be sentimental about suffering – enough is certainly as good as a feast – but people who cannot suffer can never grow up, can never discover who they are. That man who is forced each day to snatch his manhood, his identity, out of the fire of human cruelty that rages to destroy it knows, if he survives his effort, and even if he does not survive it, something about himself and human life that no school on earth – and, indeed, no church – can teach."
        </p>
        <p>
        V. "After the Civil War, the form of slavery changed from chattel to economic slavery, and we were thrown onto the labor market to compete at a disadvantage with poor whites. Ever since that time, our principal enemy must be isolated and identified as capitalism…It was the capitalist infrastructure of Europe and the U.S. which was responsible for the rape of Africa and Asia. Capitalism murdered those 30 million in the Congo."
        </p>
        <p>
        VI. “Has any people ever been independent that owned neither land nor tool?”
        </p>
        <p>
        VII. "It is true that if care is taken to use only a language that is understood by graduates in law and economics, you can easily prove that the masses have to be managed from above. But if you speak the language of everyday, if you are not obsessed by the perverse desire to spread confusion and to rid yourself of the people, then you will realize that the masses are quick to seize every shade of meaning and to learn all the tricks of the trade. If recourse is had to technical language, this signifies that it has been decided to consider the masses as uninitiated."
        </p>
        <p>
        VIII. "The people come to understand [that] wealth is not the fruit of labor but the result of organized, repeated robbery." 
        </p>
        <p>
        IX. "We should certainly know by now that it is one thing to overthrow a dictator or repel an invader and quite another thing really to achieve a revolution. Time and time and time again, the people discover that they have merely betrayed themselves into the hands of yet another Pharaoh, who, since he was necessary to put the broken country together, will not let them go."
        </p>
        <p>
        This came out more like a collage than I expected. I find that last quote especially meaningful given tomorrow's events.
        </p>
        <p>
        One of the things that I hope you take away from this game (besides the fun of it) is the realization that the descriptors I use in the title aren't so clear cut. Each of these figures, Baldwin, Fanon, and Jackson, can rightly be called a poet, a foreigner, and a prisoner, in various proportions and under different lights.
        </p>
        <p>
        And if I'm allowed to take a long leap, the next conclusion is that everyone is everyone, and that we can choose to be everyone together, or everyone apart. 
        </p>
        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>

  );
};
export default PoetForeignerPrisoner;